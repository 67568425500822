import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import Chart, { ChartList } from "../../components/charts/Chart";
import Loader from "../../components/Loader";
import NavPills from "../../components/NavPills";
import { getPatrimonyHistoryByAccount } from "../../services/patrimony.service";
import { Transaction } from "../../types/movements";
import { HistoryTimeFrame } from "./history/PatrimonyHistory";
import { NavPillsContainer } from "./Patrimony";

const timeFrameOptions: Array<{
  id: HistoryTimeFrame;
  label: string;
  miniLabel?: string;
}> = [
  { id: "UP_TO_1_MONTH", label: "1 mois", miniLabel: "1 mois" },
  { id: "UP_TO_3_MONTHS", label: "3 mois", miniLabel: "3m" },
  { id: "UP_TO_6_MONTHS", label: "6 mois", miniLabel: "6m" },
  { id: "UP_TO_1_YEAR", label: "1 an", miniLabel: "1 an" },
  { id: "UP_TO_3_YEARS", label: "3 ans", miniLabel: "3a" },
  { id: "ALL", label: "Tout" },
];

const loadAllTimeFramesData = async (biAccountId?: number) => {
  if (!biAccountId) {
    return new Map<HistoryTimeFrame, ChartList[]>();
  }
  const timeFrames: HistoryTimeFrame[] = timeFrameOptions.map((x) => x.id);
  const allTimeFramesData = new Map<HistoryTimeFrame, ChartList[]>();
  for (const timeFrame of timeFrames) {
    const patrimonyHistory = await getPatrimonyHistoryByAccount(
      timeFrame,
      biAccountId
    );

    const overAll = patrimonyHistory.map((x): ChartList => {
      const brut = x.otherAssets + x.realEstate + x.savings;
      return {
        date: x.date,
        otherAssets: x.otherAssets,
        realEstateAndOtherAssets: x.otherAssets + x.realEstate,
        brut,
        net: brut - x.credits,
      };
    });

    if (overAll.length === 1) {
      overAll.push({
        ...overAll[0],
        date: `${new Date()}`,
      });
    }

    allTimeFramesData.set(timeFrame, overAll);
  }
  return allTimeFramesData;
};

type SavingChartProps = {
  transactions: Transaction[];
  biAccountId?: number;
};

const SavingChart = (props: SavingChartProps) => {
  const [timeFrame, setTimeFrame] = useState<HistoryTimeFrame>("ALL");

  const { data: allTimeFramesData } = useQuery(
    ["patrimonyHistoryByAccount", props.biAccountId],
    () => loadAllTimeFramesData(props.biAccountId)
  );

  return (
    <div>
      <NavPillsContainer>
        <NavPills
          options={timeFrameOptions}
          onOptionSelected={setTimeFrame}
          selectedOptionId={timeFrame}
          widthMini="410px"
        />
      </NavPillsContainer>
      <div>
        {!allTimeFramesData || !allTimeFramesData.get(timeFrame) ? (
          <Loader />
        ) : (
          <Chart
            type="net"
            timeFrame={timeFrame}
            list={allTimeFramesData.get(timeFrame) || []}
          />
        )}
      </div>
    </div>
  );
};

export default SavingChart;
